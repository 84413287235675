body {
    font-family: 'Manrope', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
}

input::placeholder, textarea::placeholder {
    color: #909BAB!important;
}

button {
    letter-spacing: 4px !important;
}

a {
    margin: 0;
    padding: 0;
}

.cuneiCode-email {
    text-decoration-color: #FFF;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
}

.gradient-hero-text {
    text-transform: uppercase;
    background: linear-gradient(280.03deg, #00B0D5 2.58%, #CA17DA 51.83%, #4922CA 102.11%);
    -webkit-background-clip: text;
    /*background-clip: text;*/
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    color: transparent;
    letter-spacing: 0.2em;
    display: inline-block;
}


.gradient-service-text {
    text-transform: uppercase;
    background: linear-gradient(287.03deg, #5747D1 14.52%, #DF69F2 103.8%);
    /*background-clip: text;*/
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    letter-spacing: 0.2em;
    display: inline-block;
}

.hero-responsive-title {
    font-size: clamp(27px, 100vw * 0.0225, 36px);
    line-height: 133%;
}

.responsive-display1-title {
    font-size: clamp(40px, 100vw * 0.056, 104px);
    line-height: 120%;
}

.responsive-title-1, .responsive-title-2 {
    font-size: clamp(24px, 100vw * 0.02, 32px);
    line-height: 150%;
}

.responsive-title-3 {
    font-size: clamp(16px, 100vw * 0.02, 18px);
    line-height: 175%;
}


.responsive-body-1 {
    font-size: clamp(16px, 100vw * 0.0157, 18px);
    line-height: 180%;
}

.responsive-body-2 {
    font-size: clamp(18px, 100vw * 0.015, 24px);
    line-height: 180%;
}




